<template>
  <div class="body">
    <div class="topImg"></div>
    <div class="ltop">
      <div class="mbx">
        <div class="info">
          所在位置：
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item disabled="">搜索列表</el-breadcrumb-item>

          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="bt">

      <div class="table">
        <el-table
            cell-class-name="pointer"
            :data="tableData"
            :loading="loading"

            stripe
            header-align="center"
            style="width: 100%"
            @row-click="tableClick">
          <el-table-column
              type="index"
              width="50">
          </el-table-column>
          <el-table-column
              prop="projectType"
              align="center"
              width="150"
              label="项目类型"
          >
            <template slot-scope="scope">
              <el-tag size="small" v-if="scope.row.projectType=='1'" type="primary">一类项目</el-tag>
              <el-tag size="small" v-else type="success">二类项目</el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="title"
              align="left"
              label="公告名称"
          >
          </el-table-column>
          <el-table-column
              width="150"
              align="center"
              prop="date"
              label="发布时间">
            <template slot-scope="scope">
              {{ scope.row.createDate }}
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination1">
          <el-pagination
              @current-change="change"
              class="pagination"
              background
              :current-page="queryParams.current"
              :page-size="queryParams.size"
              :page-sizes="[5, 10, 15, 20]"
              layout="prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {searchList} from "@/api/submission";

export default {
  data() {
    return {
      total: 0,
      queryParams: {
        current: 1,
        size: 15,
        title: '',
      },
      tableData: [],
      loading: false
    };
  },
  computed: {},
  created() {
    this.getRoute()
  },
  mounted() {
    this.getList()
  },
  watch: {
    "queryParams.title": {
      handler(newValue, oldValue) {
        this.getList()
      }
    },
    $route(to, from) {
      this.queryParams.title = to.query.title

    }
  },
  methods: {
    change(val) {
      this.queryParams.current = val
      this.getList()
    },
    tableClick(row, column, event) {
      var data = {};
      data.id = row.id
      if (row.projectType == "1") {
        this.$router.push(`/Article?type=${row.type}&projectId=${row.projectId}&project=1`);
      } else if (row.projectType == "2") {
        this.$router.push(`/Article?type=${0}&projectId=${row.projectId}&project=2`);
      }
    },
    getList() {
      this.loading = true
      searchList(this.queryParams).then(({data}) => {
        this.tableData = data.records
        this.total = data.total
        this.loading = false
      })
    },
    getRoute() {
      let route = this.$route
      this.queryParams.title = route.query.title
    }
  }
};
</script>

<style lang="less" scoped>
.body {
  background-color: #FAFBFD;

  .bt {
    width: 1200px;
    margin: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 10px;

    .table {
      color: #3D3D3D;
      width: 97%;
      margin: 0 auto;


      ::v-deep .has-gutter {
        color: #004E83;
        font-size: 15px;
      }

      ::v-deep .el-table th.el-table__cell {
        background-color: #F6F8FF;
      }

      ::v-deep .el-table__row:hover .el-table__cell {
        font-weight: bold;
        color: #3783ff !important;
        background-color: #f2f2fa !important;
      }

      ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #F6F7FB;
        cursor: pointer !important;
      }

      .pagination1 {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: auto;

        .pagination {
          margin-top: 25px;
        }
      }
    }
  }
}
</style>