import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Zhaobiao from "@/views/Zhaobiao.vue";
import Zhongbiao from "@/views/Zhongbiao.vue";
import Gonggao from "@/views/Gonggao.vue";
import Us from "@/views/Us.vue";
import Article from "@/views/Article.vue";
import Register from "@/views/Register.vue";
import Login from "@/views/Login.vue";
import SearchList from "@/views/SearchList.vue";
import updatePassword from "@/views/updatePassword.vue";

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            title: "首页",
        },
    },
    {
        path: '/Us',
        name: 'Us',
        component: Us,
        meta: {
            title: "联系我们",
        },
    },
    {
        path: '/Zhaobiao',
        name: 'Zhaobiao',
        component: Zhaobiao,
        meta: {
            title: "招标信息",
        }
    },
    {
        path: '/Zhongbiao',
        name: 'Zhongbiao',
        component: Zhongbiao,
        meta: {
            title: "中标信息",
        },
    },
    {
        path: '/Gonggao',
        name: 'Gonggao',
        component: Gonggao,
        meta: {
            title: "公告信息",
        },
    },
    {
        path: '/SearchList',
        name: 'SearchList',
        component: SearchList,
        meta: {
            title: "搜索列表",
        },
    },
    {
        path: '/Article',
        name: 'Article',
        component: Article,
    },
    {
        path: '/Register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/updatePassword',
        name: 'updatePassword',
        component: updatePassword,
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    fallback: true
})
router.afterEach((to, from) => {
    // 记录路由切换完成
    if (to.fullPath==="/supplier/#/sys/dashboard/analysis/index"){
        location.reload();
    }
});
export default router
