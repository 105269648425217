import service from "@/utils/request";
import {request} from "axios";
import vueCookie from "vue-cookie";

export function getzbList(query) {
    return service({
        url: '/api/zblist',
        method: 'get',
        params: query
    })
}

export function getMenus(){
    return service({
        url: '/sys/user/getMenus',
        method: 'get',
        headers: {'Cookie': 'token=' +  vueCookie.get('token')},
    })
}

export function getAuthCode(query){
    return service({
        url: '/api/getAuthCode',
        method: 'get',
        params: query
    })
}
export function getAuthCodeLogin(query){
    return service({
        url: '/api/getAuthCodeLogin',
        method: 'get',
        params: query
    })
}

export function updatePassword(data) {
    return service({
        url: '/api/updatePassword',
        method: 'post',
        data: data
    })
}
export function getplanindex(query) {
    return service({
        url: '/api/getplanindex',
        method: 'get',
        params: query
    })
}

export function getplaninfo(query) {
    return service({
        url: '/api/getplaninfo',
        method: 'get',
        params: query
    })
}
export function getplaninfo2(query) {
    return service({
        url: '/api/getplaninfo2',
        method: 'get',
        params: query
    })
}



export function getotherList(query) {
    return service({
        url: '/api/otherlist',
        method: 'get',
        params: query
    })
}

export function getotherList2(query) {
    return service({
        url: '/api/otherlist2',
        method: 'get',
        params: query
    })
}


export function gethxlist(query) {
    return service({
        url: '/api/hxlist',
        method: 'get',
        params: query
    })
}
export function getzhongblist(query) {
    return service({
        url: '/api/zhongblist',
        method: 'get',
        params: query
    })
}
export function getbgList(query) {
    return service({
        url: '/api/bglist',
        method: 'get',
        params: query
    })
}
export function getnoticelist(query) {
    return service({
        url: '/api/noticelist',
        method: 'get',
        params: query
    })
}
export function getnoticelistindex(query) {
    return service({
        url: '/api/noticelistindex',
        method: 'get',
        params: query
    })
}

export function getProject2(query) {
    return service({
        url: '/api/project2list',
        method: 'get',
        params: query
    })
}

export function getProject2index(query) {
    return service({
        url: '/api/project2index',
        method: 'get',
        params: query
    })
}
export function getList(query,url) {
    return service({
        url: url,
        method: 'get',
        params: query
    })
}

export function findAllByEndTime() {
    return service({
        url: '/bidding/notice/find',
        method: 'post'
    })
}

export function findTwoInfo(uid) {
    return service({
        url: '/bidding/notice/findTwoInfo/'+uid,
        method: 'get',
    })
}

export function downloadZip(data) {
    return service({
        url: '/bidding/tenderFile/downloadZip',
        method: 'post',
        data: data
    })
}


export function getDict(data) {
    return service({
        url: '/api/getDictValue',
        method: 'get',
        params : data
    })
}


export function getCode() {
    return service({
        url: '/sys/getCode',
        method: 'get',
        param: {dictTypeId:'1786809368735649793'}

    })
}

