<template>
  <div class="type-nav">
    <div class="container">
      <div class="container1">
        <nav class="nav" :class="index==tabIndex?'nav1':''" v-for="(item,index) in tabList"
             @click="tab(item,index)">
          <div>{{ item.title }}</div>
        </nav>
        <div class="container2" @mouseleave="shou" @mouseenter="shou">
          <div class="text">服务中心</div>
          <div v-bind:class="{ tab: true, open: isOpen }" class="tab">
            <div class="text1" @click="go(1)">常见问题 <i class="el-icon-link"></i></div>
            <div class="text1" @click="go(2)">操作手册/教学视频 <i class="el-icon-link"></i></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Login from '@/components/login/Login.vue'

export default {
  name: 'TypeNav',
  components: {
    Login
  },
  data() {
    return {
      dialogVisible: false,
      tabList: [
        {title: '首页', name: 'home', query: {}},
        // {title: '招标信息', name: 'Zhaobiao'},
        // {title: '中标信息', name: 'Zhongbiao'},
        {title: '公告信息', name: 'Gonggao', query: {type: 'gonggao'}},
        {title: '联系我们', name: 'Us', query: {}}
      ],
      tabIndex: 0,
      url: '',
      isOpen: false
    }
  },
  watch: {
    '$route'(to, from) {
      this.tabList.forEach((item, index) => {
        if (item.name === to.name) {
          this.tabIndex = index
          sessionStorage.setItem("tabIndex", index)
        }
      })
    }
  },
  mounted() {
    const tabIndex = sessionStorage.getItem("tabIndex")
    if (tabIndex) {
      this.tabIndex = tabIndex
    }
  },
  methods: {
    tab(item, index) {
      this.$router.push({name: item.name, query: item.query})
      // this.$router.push({path: '/Zhaobiao'})
      this.tabIndex = index
      sessionStorage.setItem("tabIndex", index)
    },
    go(index) {
      if (index == 1) {
        window.open("https://orderth.yuque.com/mggd8a/iv2cyz/qrnoquiu6skikwa9?singleDoc#", "_blank")
      } else if (index == 2) {
        window.open("https://orderth.yuque.com/mggd8a/iv2cyz/ogwm1fcp1mvyf6bg?singleDoc#", "_blank")
      }
    },
    shou() {
      this.isOpen = !this.isOpen;
    }
  }
}
</script>

<style lang="less" scoped>
.type-nav {
  width: 100%;
  height: 60px;
  background: linear-gradient(270deg, #0078ed 0%, #1B8FA6 100%);

  .container {
    width: 1300px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .container1 {
      display: flex;
      justify-content: flex-start;
      //width: 800px;

      .nav {
        width: 148px;
        height: 60px;
        color: #fff;
        font-size: 18px;
        line-height: 60px;
        text-align: center;
        margin: 0 48px;
        cursor: pointer;
        transition: background-color 0.3s;

      }

      .nav:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      .nav1 {
        font-weight: 600;
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .container2 {
      position: relative;
      display: flex;
      line-height: 60px;
      height: 60px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
      width: 148px;
      text-align: center;
      flex-direction: column;
      align-items: center;

      .text {
        width: 100%;
        transition: background-color 0.3s;

      }

      .tab {
        position: absolute;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        width: 180px;
        z-index: 999;
        left: -17px;
        top: 60px;
        max-height: 0;
        border-radius: 0 0 5px 5px;
        background-color: #0B81D1;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        box-shadow: 0 0 0 0 #0f78bf;


        .text1 {
          font-size: 16px;
          width: 150px;
          border-bottom: 1px solid #ffffff;
        }

        .text1:last-child {
          width: 180px;
          border-bottom: none;
        }
      }

      .tab.open {
        max-height: 500px; /* 这个值应根据内容的最大高度进行调整 */
        box-shadow: 0 2px 5px 1px #0f78bf;
        transition: 0.3s;

      }
    }

    .container2 .text:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }


}
</style>
