<template>
  <div style="background: rgba(244, 244, 244)">
    <ListContainer></ListContainer>
    <MoreList></MoreList>
    <BiddingList></BiddingList>
  </div>
</template>


<script>
import ListContainer from "@/components/home/ListContainer.vue";
import MoreList from "@/components/home/MoreList.vue";
import BiddingList from "@/components/home/BiddingList.vue";

export default {
  name: 'homeView',
  components: {
    ListContainer,
    MoreList,
    BiddingList,
  },
}
</script>
