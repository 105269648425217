import { render, staticRenderFns } from "./MoreList.vue?vue&type=template&id=dae0aad2&scoped=true"
import script from "./MoreList.vue?vue&type=script&lang=js"
export * from "./MoreList.vue?vue&type=script&lang=js"
import style0 from "./MoreList.vue?vue&type=style&index=0&id=dae0aad2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae0aad2",
  null
  
)

export default component.exports