<template>
  <div class="listBody">
    <div class="form1">
      <div class="text">
        <div>发布时间:</div>
        <div class="s" :class="item.type===fromData.fbsj_type?'fbsj':''" style="margin-left: 32px"
             v-for="(item) in fbsj" @click="click_fbsj(item)">{{ item.title }}
        </div>
      </div>
      <div class="date s">
        <el-date-picker
            v-model="pickerDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            size="small"
            @change="fbsj_date"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="form2">
      <div class="input">
        <div class="i">
          <div class="title">公告名称：</div>
          <el-input v-model="queryParams.title"
                    size="small"
                    style="width:400px"
                    placeholder="输入公告名称"></el-input>
        </div>
      </div>
      <div class="ss s">
        <el-button plain type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
      </div>
      <div class="cz s">
        <el-button plain type="info" icon="el-icon-remove-outline" size="small" @click="reset">重置</el-button>

      </div>
    </div>
    <div class="table">
      <el-table
          v-loading="loading"
          cell-class-name="pointer"
          :data="tableData"
          stripe
          header-align="center"
          style="width: 100%"
          @row-click="tableClick">
        <el-table-column
            type="index"
            width="50">
        </el-table-column>
        <el-table-column
            prop="title"
            align="left"
            label="公告名称"
        >
        </el-table-column>
        <el-table-column
            width="150"
            align="center"
            prop="date"
            label="发布时间">
          <template slot-scope="scope">
            {{ scope.row.createDate.substr(0,11) }}
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination1">
        <el-pagination
            class="pagination"
            background
            :current-page="queryParams.current"
            :page-size="queryParams.size"
            :page-sizes="[5, 10, 15, 20]"
            layout="prev, pager, next"
            @current-change="change"
            :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {getList} from "@/api/home";
import moment from "moment";

export default {
  props: {
    tabIndex: {
      type: Number,
      default: 0,
    },
    url: {
      type: String,
      default: "",
    }
  },
  name: "List",
  watch: {},
  data() {
    return {
      loading:false,
      fbsj: [
        {type: 1, title: '全部', beginTime: '', endTime: ''},
        {type: 2, title: '近1天', beginTime: '', endTime: ''},
        {type: 3, title: '近3天', beginTime: '', endTime: ''},
        {type: 4, title: '近一周', beginTime: '', endTime: ''}
      ],
      fromData: {
        fbsj_type: 1,
        on: '',
      },
      pickerDate: [],
      tableData: [],
      queryParams: {
        current: 1,
        size: 15,
        title: '',
        beginTime: '',
        endTime: '',
      },
      total: 0,
      q:{},
    }
  },
  created() {
    let time = moment().format('YYYY-MM-DD HH:mm:ss')
    const DayAgo1 = moment().clone().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss');
    const DayAgo3 = moment().clone().subtract(3, 'day').format('YYYY-MM-DD HH:mm:ss');
    const DayAgo7 = moment().clone().subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss');
    this.fbsj[1].beginTime = DayAgo1
    this.fbsj[2].beginTime = DayAgo3
    this.fbsj[3].beginTime = DayAgo7
    this.fbsj[1].endTime = time
    this.fbsj[2].endTime = time
    this.fbsj[3].endTime = time
  },
  methods: {
    change(val) {
      this.queryParams.current = val
      this.getinfo()
    },
    init(type) {
      this.outFromData = type
      this.getinfo()
    },
    getinfo() {
      this.loading = true
      getList({
        ...this.queryParams,
        ...this.outFromData
      }, this.url).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
        this.loading = false;
      })
    },
    search() {
      this.getinfo()
    },
    reserForm() {
      this.fromData.fbsj_type = 1;
      this.queryParams.current = 1
      this.queryParams.size = 15
      this.queryParams.title = ''
      this.queryParams.beginTime = ''
      this.queryParams.endTime = ''
      this.pickerDate = ["", ""]
    },
    reset() {
      this.reserForm()
      this.getinfo()
    },
    click_fbsj(item) {
      this.fromData.fbsj_type = item.type
      this.pickerDate = []
      this.queryParams.beginTime = item.beginTime
      this.queryParams.endTime = item.endTime
      this.getinfo(this.url)
    },
    fbsj_date(e) {
      this.fromData.fbsj_type = 0
      this.queryParams.beginTime = e[0]
      this.queryParams.endTime = e[1]
    },
    tableClick(row, column, event) {
      var data = {};
      data.id = row.id
      if (this.url=="/api/noticelist"){
        this.$router.push(`/Article?id=${row.id}&type=${10}`);
      }else if (this.url=="/api/getPlan1List"){
        this.$router.push(`/Article?type=${this.outFromData.type}&projectId=${row.projectId}&project=1`);
      }else if (this.url=="/api/project2index"){
        this.$router.push(`/Article?type=${0}&projectId=${row.id}&project=2`);
      }

      // let newPageUrl
      // if (this.url == "/api/noticelist") {
      //   newPageUrl = this.$router.resolve(`/Article?id=${row.id}&type=${10}`).href;
      // } else if (this.url == "/api/getPlan1List") {
      //   newPageUrl = this.$router.resolve(`/Article?type=${this.outFromData.type}&projectId=${row.projectId}&project=1`).href;
      // } else if (this.url == "/api/getPlan2List") {
      //   newPageUrl = this.$router.resolve(`/Article?type=${0}&projectId=${row.id}&project=2`).href;
      // }
      // window.open(newPageUrl, '_blank');
    }
  }
}
</script>

<style scoped lang="less">
.listBody {
  width: 100%;
  background-color: #fff;
  padding: 20px 29px;

  .s {
    cursor: pointer;
  }

  .table {
    margin-top: 36px;
    color: #3D3D3D;

    ::v-deep .has-gutter {
      color: #004E83;
      font-size: 15px;
    }

    ::v-deep .el-table th.el-table__cell {
      background-color: #F6F8FF;
    }

    ::v-deep .el-table__row:hover .el-table__cell {
      font-weight: bold;
      color: #3783ff !important;
      background-color: #f2f2fa !important;
    }

    ::v-deep .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #F6F7FB;
      cursor: pointer !important;
    }

    .pagination1 {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: auto;

      .pagination {
        margin-top: 25px;
      }
    }
  }

  .form2 {
    margin-top: 23px;
    display: flex;
    align-items: center;

    .input {
      width: 500px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .i {
        display: flex;
        align-items: center;

        .title {
          font-weight: 400;
          font-size: 14px;
          color: #3D3D3D;
          line-height: 14px;
        }
      }
    }

    .ss {
      margin-left: 64px;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 12px;
      color: #409EFF;
      width: 84px;
      height: 32px;
      border-radius: 4px 4px 4px 4px;
      text-align: center;
      line-height: 32px;
    }

    .cz {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 84px;
      height: 32px;
      border-radius: 4px 4px 4px 4px;
      font-weight: 700;
      font-size: 12px;
      color: #909399;

    }
  }

  .form1 {
    display: flex;
    align-items: center;

    .text {
      display: flex;
      align-items: center;
      width: 375px;
      font-weight: 400;
      font-size: 14px;
      color: #3D3D3D;
      line-height: 14px;
    }

    .date {

    }


    .fbsj {
      color: #0B83D9;
      font-size: 15px;
      font-weight: bold;
    }
  }
}

/deep/ .pointer {
  cursor: pointer;
}
</style>