<template>
  <div id="app" >
    <HomeHeader></HomeHeader>
    <TypeNav></TypeNav>
    <router-view/>
    <HomeFloor></HomeFloor>
  </div>
</template>
<script>
import HomeHeader from "@/components/home/HomeHeader.vue";
import TypeNav from "@/components/home/TypeNav.vue";
import HomeFloor from "@/components/home/HomeFloor.vue";

export default {
  name: 'homeView',
  components: {
    HomeHeader,
    TypeNav,
    HomeFloor
  }
}
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}


</style>
