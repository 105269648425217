<template>
  <div class="list-container" style="background: rgba(244, 244, 244)">
    <div class="container">

      <div class="left">
        <div class="title">
          <span class="text1">奥德阳光招采平台</span>
        </div>
        <div class="tabList">
          <div class="tab" v-for="(item,index) in tabList" :style='"background-image: url("+item.img+");"'>
            <img :src="item.icon">
            <div>
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="right"  v-loading="loading">
        <div class="login">


          <div class="title">
            供应商登录
          </div>
          <div class="input">
            <el-tabs v-model="loginParams.loginType">
              <el-tab-pane label="密码" name="1">
                <el-input v-model="loginParams.phone" placeholder="请输入注册手机号或者统一信用代码" class="phone"/>
                <el-input type="password" show-password v-model="loginParams.password" placeholder="请输入密码" class="password"/>
              </el-tab-pane>
              <el-tab-pane label="验证码" name="2">
                <el-input  v-model="loginParams.phone"  placeholder="请输入注册手机号" class="phone"/>
                <div class="yzminput">
                  <el-input v-model="loginParams.phoneCode" placeholder="验证码" class="phoneCode"/>
                  <el-button :disabled="isDisabled" type="primary" class="bt" @click="startCountdown">获取验证码
                    <span v-if="isDisabled">({{ countdown }})</span>
                  </el-button>
                </div>
              </el-tab-pane>
            </el-tabs>

          </div>
          <div class="wjmm" @click="wjmm">忘记密码</div>
          <div class="btns">
            <el-button class="login" type="primary" @click="login()">登录</el-button>
            <el-button class="register" @click="$router.push({name: 'Register'})">注册</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {loginPassword} from "@/api/system/supplier";
import vueCookie from "vue-cookie";
import {getAuthCode, getAuthCodeLogin, getMenus} from "@/api/home";

export default {
  name: 'ListContainer',
  data() {

    return {
      tabList: [
        {title: '开放透明', img: '../images/zb/tab1.png', icon: '../images/zb/icon1.png'},
        {title: '资源共享', img: '../images/zb/tab2.png', icon: '../images/zb/icon2.png'},
        {title: '诚信生态', img: '../images/zb/tab3.png', icon: '../images/zb/icon3.png'},
        {title: '公平公正', img: '../images/zb/tab4.png', icon: '../images/zb/icon4.png'}
      ],
      loginParams: {
        loginType: "1",
        phone: '',
        password: '',
        phoneCode: '',
      },
      countdown: 60,
      isDisabled: false,
      timer: null,
      loading:false,
    };
  },
  methods: {
    wjmm(){
      this.$router.push({name: 'updatePassword'})
    },
    startCountdown(){
      if (this.loginParams.phone==""||!(/^1\d{10}$/.test(this.loginParams.phone))){
        this.$message.error("请填写正确手机号")
        return;
      }

      if (this.isDisabled) return;
      this.isDisabled = true;
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
          this.isDisabled = false;
          this.countdown = 60;
        }
      }, 1000);
      getAuthCodeLogin({phone:this.loginParams.phone}).then((res)=>{
        this.$message.success(res.data)
      }).catch((res)=>{
        this.$alert(res.response.data, '提示',{
          confirmButtonText: '确定',
          type: 'error',
        })
        clearInterval(this.timer);
        this.isDisabled = false;
        this.countdown = 60;
      })
    },
    login(){
      if (this.loginParams.phone==""){
        this.$message.error("请填写手机号")
        return
      }

      if (this.loginParams.loginType=="1"){
        if (this.loginParams.password==""){
          this.$message.error("请填写密码")
          return
        }
      }else if (this.loginParams.loginType=="2"){
        if (this.loginParams.phoneCode==""){
          this.$message.error("请填写验证码")
          return
        }
        if (!(/^1\d{10}$/.test(this.loginParams.phone))){
          this.$message.error("请填写正确手机号")
        }
      }
      this.loading = true
      loginPassword(this.loginParams).then((res) => {
        vueCookie.set('token', res.data.token)
        getMenus(res.data.token).then(({data}) => {
          localStorage.setItem('routerList', JSON.stringify(data.routerList || '[]'))
          localStorage.setItem('allMenuList', JSON.stringify(data.menuList || '[]'))
          localStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'))
          localStorage.setItem('dictList', JSON.stringify(data.dictList || '[]'))
          this.$router.push({path: '/supplier/#/sys/dashboard/analysis/index'})
        })
        this.$notify({
          title: '登录成功',
          message: `欢迎回来！`,
          dangerouslyUseHTMLString: true,
          duration: 10000,
          type: 'success'
        })
      }).catch((err) => {
        this.$message.error(err.response.data);
      }).finally(()=>{
        this.loading = false;
      })
    }
  }
}
</script>


<style lang="less" scoped>
.list-container {
  height: 333px;
  display: flex;
  background: rgba(244, 244, 244, 0.74);

  .container {
    width: 1200px;
    display: flex;
    margin: 20px auto;

    .left {
      width: 783px;
      background-image: url("/public/images/zb/banner.png");
      background-size: cover;
      border-radius: 10px;
      .title {
        color: #fff;
        display: flex;
        justify-content: center;
        margin: 80px auto 50px;
        font-size: 50px;
        font-weight: bold;
      }

      .tabList {
        display: flex;
        justify-content: space-between;
        margin: 0 40px;

        .tab {
          background-position: 90%;
          background-size: cover;

          img {
            width: 20px;
            height: 20px;
          }

          color: #fff;
          width: 140px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
        }
      }
    }

    .right {
      width: 365px;
      margin: 0 0 0 20px;
      padding: 0 20px;
      border-radius: 10px;
      background: #fff;
      background-image: url("/public/images/zb/loginbg.png");
      background-size: cover;

      .login {
        .title {
          text-align: center;
          margin: 10px auto 5px;
          color: #0866aa;
          font-size: 25px;
          font-weight: bold;
        }

        .input {
          margin: 0 auto;
          width: 80%;

          .phone {
            margin-bottom: 10px;
          }

          .yzminput {
            display: flex;

            .bt {
            }
          }
        }

        .btns {
          display: flex;
          flex-direction: row;

          .login {
            margin: 0 auto;
            width: 160px;
            margin-top:10px
          }

          .register {
            margin: 0 auto;
            width: 160px;
            margin-top:10px

          }
        }

        .wjmm{
          width: 100%;
          text-align: end;
          font-size: 14px;
          color: #666;
          margin-top: 10px;
          cursor: pointer;
        }
      }

    }

    .demo-ruleForm {
      width: 360px;
    }
  }
}
</style>
