<template>
    <div class="body">
        <div class="topImg"></div>
        <div class="ltop">
            <div class="mbx">
                <div class="info">
                    所在位置：
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item v-for="(item,index) in mbx" :key="index">{{ item.title }}</el-breadcrumb-item>
                        <el-breadcrumb-item>{{ tabList[tabIndex].title }}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>
        <div class="bt">
            <div class="left ListLeftTab">
                <div class="info" :class="index ===tabIndex?'info1':''" v-for="(item,index) in tabList"
                     @click="clickTab(index)">
                    <div class="text">
                        {{ item.title }}
                    </div>
                </div>
            </div>
            <div class="right">
                <List :tabIndex="tabIndex+2" :name="name" :url="url"></List>
            </div>
        </div>
    </div>
</template>

<script>
import List from "@/views/List.vue";

export default {
    name: "Zhongbiao",
    components: {List},
    data() {
        return {
            name: 'Zhongbiao',
            routerList: [],
            tabIndex: 0,
            tabList: [
                {
                    title: '中标候选人公示',
                    url: '/api/hxlist'
                }, {
                    title: '中标公示',
                    url: '/api/zhongblist'
                }
            ],
            mbx: [],
            url: ''
        }
    },
    created() {
        this.getRoute()
        this.url = this.tabList[this.tabIndex].url

    },
    mounted() {
    },
    methods: {

        clickTab(index) {
            this.tabIndex = index
            this.url = this.tabList[this.tabIndex].url
        },
        getRoute() {
            let router = this.$router
            let route = this.$route
            let mbx = []
            route.matched.forEach((item) => {
                let data = {
                    title: item.meta.title,
                    name: item.name
                };
                mbx.push(data)
            })
            this.mbx = mbx

            this.routerList = router.options.routes.filter((item) => {
                return item.name === route.matched[0].name
            })[0].children
        }
    }
}
</script>

<style scoped lang="less">
.body {
  width: 100%;
  background-color: #FAFBFD;


  .bt {
    width: 1200px;
    margin: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;


    .right {
      width: 929px;
    }
  }
}


</style>