  <template>
  <header class="header">
    <div class="cs"></div>

    <!--头部第二行 搜索区域-->
    <div class="bottom">
      <div class="logoArea">
        <router-link class="logo" title="招投标服务平台" to="/">
          <el-image
            style="width: auto; height: 50px;"
            src="../images/zb/logo.png"
          ></el-image>
        </router-link>
        <div class="shu"></div>
        <div class="title">奥德阳光招采平台</div>
      </div>
      <div class="input">
        <el-input clearable placeholder="请输入内容" v-model="title" class="input-with-select" size="mini"></el-input>
        <div class="input_bt" @click="goSearch"><i class="el-icon-search"></i></div>
      </div>
    </div>
  </header>
</template>


<script>

import {bus} from "@/main";

export default {
  name: 'homeView',
  data(){
    return {
      title: '',
      is_disappear: true
    }
  },
  mounted(){
    if (sessionStorage.getItem('token') == null) {
      this.is_disappear = false
    }
  },
  created(){
    bus.$on('update:login', (data) => {
      this.is_disappear = data
    })
  },
  methods: {
    goSearch(){
      if (this.title==""){
        // this.$message.error("请输入内容")
        // return
      }
      this.$router.push({name: "SearchList",query:{title: this.title }})
    },
    exit(){
      if (sessionStorage.getItem('token') != null) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('bsSupplier')
        this.$message.success("退出成功！")
        this.$router.push({path: '/'});
        this.is_disappear = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  position: relative;
  .cs{
    position: absolute;
    background-size: cover;
    background-image: url("/public/images/zb/cs.png");
    width: 339px;
    height: 118px;
    left: 780px;
    top: -36px;
    z-index: -1;
  }
  & > .top {
    background-color: #FFFFFF;
    height: 30px;
    line-height: 30px;

    .container {
      width: 1180px;
      margin: 0 auto;
      overflow: hidden;

      .loginList {
        float: left;

        p {
          float: left;
          margin-right: 10px;

          .register {
            cursor: pointer;
            border-left: 1px solid #b3aeae;
            padding: 0 5px;
            margin-left: 5px;
          }
        }
      }

      .typeList {
        float: right;

        a {
          padding: 0 10px;

          & + a {
            border-left: 1px solid #b3aeae;
          }
        }
      }
    }
  }

  & > .bottom {
    width: 1180px;
    margin: 10px auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logoArea {
      display: flex;
      align-items: center;

      .logo {
        height: 57px;
        img {
        }
      }

      .shu {
        width: 1px;
        height: 24px;
        background: #09509E;
        margin: 0 20px 0 20px;
      }

      .title {
        width: 300px;
        height: 37px;
        font-weight: 700;
        font-size: 22px;
        color: #09509E;
        line-height: 38px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }

    .input {
      width: 320px;
      display: flex;
      border: 1px solid #EFEFEF;
      border-radius: 10px;
      height: 35px;

      .input-with-select {
        width: 266px;
        height: 35px;

        ::v-deep .el-input__inner {
          width: 266px;
          height: 35px;
          border: 0;
          background: #F9F9F9;
          border-radius: 10px 0 0 10px;
        }
      }

      .input_bt {
        width: 60px;
        height: 35px;
        background: #0D83CD;
        border-radius: 0 10px 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 20px;
        cursor: pointer;
      }
    }


  }
}

</style>
