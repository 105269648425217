import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/global.css' // global css
import '@/assets/global1.less' // global css

Vue.config.productionTip = false
Vue.use(ElementUI);

export const bus = new Vue();

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
