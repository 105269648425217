<template>
    <div class="boxBidding">
      <div class="list_left1">
        <div style="display: flex;align-items: center">
          <div class="shu"></div>
          <div class="zi">二类项目</div>
        </div>
        <div class="gd" @click="$router.push({name: 'Gonggao',query:{type:'project2'}})">更多></div>

      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick" style="padding: 0 5px">
        <el-tab-pane :label="item.label"  :name="item.value" v-for="(item,index) in tabs" :key="index"></el-tab-pane>
      </el-tabs>
      <div class="list_left4"  v-loading="loading" >
        <div class="tonzhi" v-for="(item,index) in list"
             @click="newDetail(item)">
          <div class="left">
            <img src="/images/zb/yy.png">
            <div class="text2">{{ item.title }}
            </div>
          </div>
          <div class="riqi">{{ item.createDate.split(" ")[0] }}</div>
        </div>

        <div v-if="list.length ==0" style="text-align: center;padding: 100px">暂无内容</div>
      </div>
    </div>
</template>
<script>
import {gethxlist, getzhongblist, getDict, getProject2, getProject2index} from "@/api/home";

export default {
  name: 'MoreList',
  data() {
    return {
      activeName: 'HD',
      index: 0,
      tabs: [],
      list: [],
      loading:false
    }
  },
  created() {
    this.Info();
  },
  methods: {
    newDetail(item){
      window.open(`/Article?type=${item.type?item.type:0}&projectId=` + item.id+'&project=2');
    },
    handleClick(tab, event) {

      this.getList(this.tabs[tab.index].value)
    },
    //查询招标公示
    getList(type){
      this.loading = true
      let queryParams1 = {
        current: 1,
        size: 6,
        area: type,
      }
      getProject2index(queryParams1).then(res => {
        this.list = res.data.records;
        this.loading=false
      })
    },
    Info() {
      getDict({dictTypeId:'1786809368735649793'}).then(res => {
        this.tabs = res.data;
        this.getList(this.tabs[0].value)
      })
    }

  }
}
</script>
<style lang="less" scoped>
.boxBidding {
  width: 1200px;
  margin: 15px auto 0px;
  background: #FFFFFF;
  box-shadow: 0 4px 15px 0 #EFEFEF;
  border-radius: 8px 8px 8px 8px;
  padding-bottom: 20px;

  .list_left1 {
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .shu {
      width: 4px;
      height: 16px;
      background: #0059BC;
      margin-left: 28px;
      margin-right: 10px;
    }

    .zi {
      font-size: 22px;
      color: #0059BC;
    }
    .gd{
      color: #8D8D8D;
      font-size: 14px;
      cursor: pointer;
      padding-right: 30px;
    }
  }

  .list_left2 {
    width: 1141px;
    height: 2px;
    background: #EFEFEF;
    margin: auto;
  }

  .list_left3 {
    width: 1141px;
    margin: 15px auto;
    display: flex;
    justify-content: space-between;

    .list_left31 {
      display: flex;
      color: #333333;
      font-size: 18px;

      .tab1 {
        width: 114px;
        height: 35px;
        background: #EFEFEF;
        border-radius: 111px 111px 111px 111px;
        line-height: 35px;
        text-align: center;
        margin-right: 25px;
        cursor: pointer;
      }

      .tab2 {
        width: 114px;
        height: 35px;
        background: #EFEFEF;
        border-radius: 111px 111px 111px 111px;
        line-height: 35px;
        text-align: center;
        cursor: pointer;
      }

      .tab3 {
        color: #1971FA;
        background-color: #E7F1FF;
      }
    }

    .list_left32 {
      color: #8D8D8D;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .list_left4 {
    width: 1141px;
    margin: auto;
    font-size: 15px;
    min-height: 250px;

    .tonzhi:hover {
      color: #3783ff;
      background-color: #f2f2fa;
    }

    .tonzhi {
      display: flex;
      white-space: nowrap;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #EFEFEF;
      color: #4b4b4b;
      padding: 10px 0;
      cursor: pointer;

      &:hover {
        color: #3e85f5;
        background-color: #f8fbff;
      }

      .left {
        display: flex;
        align-items: center;
        width: 880px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 16px;

        img {
          width: 20px;
          height: 20px;
        }

        .text2 {
          overflow: hidden;
          text-overflow: ellipsis;
          padding-left: 10px;

        }
      }

      .riqi {
      }
    }
  }
}

.xglj {
  width: 1200px;
  margin: auto;
  padding-top: 50px;

  .title {
    font-weight: 700;
    font-size: 24px;
    color: #4b4b4b;
    padding-bottom: 39px;
  }

  .list {
    display: flex;
    justify-content: space-between;
    padding-bottom: 48px;

    .info {
      width: 250px;
      height: 73px;
      border: 1px solid #D1D1D1;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

/deep/ .el-tabs__item {
  font-size: 15px;
}

/deep/ .el-tabs__item.is-active{
  color:#fff !important;
  background: #3692f7;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
/deep/ .el-tabs__item.is-top:nth-child(2)  {
  padding-left: 20px !important;
}
/deep/ .el-tabs--top .el-tabs__item.is-top:last-child{
  padding-right: 20px;
}
/deep/ .el-tabs__item:hover {
  color: #3692f7;
}
/deep/ .el-tabs__active-bar{
  color: #3692f7;
}
/deep/ .el-icon-arrow-left:before,/deep/ .el-icon-arrow-right:before{
  font-size: 20px;
  font-weight: bold;
}
/deep/ .el-tabs__nav-wrap.is-scrollable{
  padding: 0 30px !important;
}
</style>
