<template>
  <div class="body">
    <div class="topImg"></div>
    <div class="ltop">
      <div class="mbx">
        <div class="info">
          所在位置：
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item,index) in mbx" :key="index">
              {{ item.title }}
            </el-breadcrumb-item>
            <el-breadcrumb-item>详细信息</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="bt">
      <div class="left" v-if="projectType" v-loading="indexloading">
        <div class="info" :class="index == tabIndex?'info1':''" v-for="(item,index) in stepList"
             @click="clickTab(index)"
             :style="{color: hasType(index)  ?'#999':'',cursor: !hasType(index) ?'pointer':'not-allowed'}">
          <div class="text">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="right" v-loading="loading" :style="{width:!projectType?'100%':'890px'}">
        <div class="title">
          {{ data.title }}
        </div>
        <div class="tab" v-if="data.createDate">
          【信息来源:奥德集团】【信息时间:{{ data.createDate }} 】
        </div>
        <div v-html="data.content" class="content"  :style="{width:!projectType?'100%':'890px'}"></div>
        <div v-if="data.files" style="margin-top: 10px;">
          <div style="display: flex;align-items: center;margin-bottom: 10px">
            <div class="shu"></div>
            <div class="download">附件</div>
          </div>
          <p v-for="item in data.files.split('|')" >
            <span class="name file" @click="download(item)" >{{ getFileName(item) }}</span><a @click="download(item)" class="file" >下载</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getplaninfo, getnoticelist, getotherList, getplaninfo2, getotherList2} from "@/api/home";

export default {
  name: "Zhaobiao",
  data() {
    return {
      loading: true,
      indexloading: true,
      data: {
        title: '',
        files: ''
      },
      id: '',
      tabIndex: 0,
      projectType: '0',
      projectId: '',
      stepList: [
        '招标计划',
        '招标公告',
        '投标单位公示',
        '踏勘与答疑',
        '开标评标定标',
        '中标通知书',
        '招标后评价',
      ],
      allPlan: [],
      mbx: [],
    }
  },
  created() {
  },
  mounted() {
    this.init();
  },
  methods: {
    hasType(index) {
      return !this.allPlan.find(item => item.type == index);
    },
    getFileName(url) {
      return url.substr(url.lastIndexOf('/') + 1);
    },
    download(url) {
      const a = document.createElement('a')
      a.setAttribute('target', '_blank')
      a.setAttribute('href', url)
      a.click()
    },
    init() {
      this.tabIndex = this.$route.query.type;
      this.id = this.$route.query.id
      this.projectType = this.$route.query.project
      this.projectId = this.$route.query.projectId
      //projectType=1 一类项目各阶段 projectType=2：二类项目
      if (this.projectType == '1') {
        getotherList({projectId: this.projectId}).then(rr => {
          this.allPlan = rr.data;
          this.indexloading = false;
        })
        getplaninfo({projectId: this.projectId, type: this.tabIndex}).then(res => {
          this.data = res.data
          this.tabIndex = this.data.type;
          this.loading = false;
        })
      } else if (this.projectType == '2') {
        getotherList2({projectId: this.projectId}).then(rr => {
          this.allPlan = rr.data;
          this.indexloading = false;
        })
        getplaninfo2({projectId: this.projectId, type: this.tabIndex}).then(res => {
          this.data = res.data
          this.tabIndex = this.data.type;
          this.loading = false;
        })
      } else {
        getnoticelist({id: this.id}).then(res => {
          this.data = res.data.records[0];
          this.loading = false;
        })
      }
    },
    clickTab(index) {
      if (this.tabIndex != index) {
        if (this.hasType(index)) return
        this.tabIndex = index
        let plan = this.allPlan.find(item => item.type == index);
        this.loading = true;
        this.$router.push({
          name: 'Article',
          query: {type: index, id: plan.id, project: this.projectType, projectId: this.projectId}
        })
        this.init();
      }
    },
  }
}
</script>

<style scoped lang="less">
.body {
  width: 100%;
  background-color: #FAFBFD;


  .bt {
    width: 1200px;
    margin: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 240px;
      color: #002D64;
      font-size: 18px;

      .info {
        width: 240px;
        height: 60px;
        background: #FFFFFF;

        .text {
          height: 59px;
          line-height: 59px;
          text-align: center;
          width: 228px;
          margin: auto;
          border-bottom: 1px solid #F0F0F0;
        }
      }

      .info1 {
        background: linear-gradient(270deg, #0980D9 0%, #2DB5DF 100%);
        font-weight: 700;
        color: #fff;

        .text {
          border-bottom: 0 solid #F0F0F0;
        }
      }
    }

    .right {
      width: 890px;
      background-color: #fff;
      padding: 20px;

      .title {
        font-weight: bold;
        font-size: 20px;
        padding: 0 30px;
        text-align: center;
      }
      .content{
        width: 890px;
        overflow-x: auto;
      }

      .tab {
        margin: 20px auto;
        width: 100%;
        height: 20px;
        color: #797573;
        padding: 4px;
        text-align: center;
      }
    }
  }
}

.fujian:hover {
  text-decoration: underline;
}
.shu {
  width: 3px;
  height: 20px;
  background: #09509E;
  margin-right: 5px;
}

.download {
  font-size: 14px;
  color: #0059BC;
  font-weight: bold;
  border-radius: 2px;
}

.file {
  cursor: pointer;
  padding-left: 10px;
  line-height: 28px;
  color: #3e85f5;
  font-size:14px;
  &:hover {
    text-decoration: underline;
    color: #315efb;
    font-weight: bold;
  }
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse; /* 使相邻的边框合并为一个 */
}
</style>