<template>
  <div class="Morebox">
    <div class="body">

      <div class="list_left">
        <div class="list_left1">
          <div style="display: flex;align-items: center">
            <div class="shu"></div>
            <div class="zi">一类项目</div>
          </div>
          <div class="gd" @click="$router.push({name: 'Gonggao',query:{type:'project1'}})">更多></div>
        </div>
        <div class="list_left3">
          <el-tabs v-model="activeone" @tab-click="handleClick" style="padding: 0 5px">
            <el-tab-pane :label="item" :name="index.toString()" v-for="(item,index) in stepList"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="list_left4" v-loading="loading1">
          <div class="tonzhi" v-for="(item,index) in planList"
               @click="newDetail(item)">
            <div class="left">
              <img src="/images/zb/yy.png">
              <div class="text2">{{ item.title }}</div>
            </div>
            <div class="riqi">{{ item.createDate.split(" ")[0] }}</div>
          </div>
          <div v-if="planList.length ==0" style="text-align: center;padding: 100px">暂无内容</div>
        </div>
      </div>

      <div class="list_right">
        <div class="list_right1">
          <div style="display: flex;align-items: center">
            <div class="shu"></div>
            <div class="zi">公告信息</div>
          </div>
          <div class="gd" @click="$router.push({name: 'Gonggao',query:{type:'gonggao'}})">更多></div>
        </div>
        <div class="list_right3">
          <img src="/images/zb/gg.png" class="gg">
          <el-tabs v-model="ggactiveName" v-loading="loading2">
            <el-tab-pane v-for="(item,index) in ggList" :key="item.id" :label="item.label" :name="item.label">
              <div class="text" v-for="item1 in noticeList[item.value]"
                   @click="newgg(item1)">
                <div class="text2">{{ item1.title }}</div>
              </div>
            </el-tab-pane>
          </el-tabs>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getDict, getnoticelistindex, getplanindex} from "@/api/home";

export default {
  name: 'MoreList',
  data() {
    return {

      activeone: '0',
      stepList: [
        '招标计划',
        '招标公告',
        '投标单位公示',
        '踏勘与答疑',
        '开标评标定标',
        '中标通知书',
        '招标后评价',
      ],
      noticeList: [],
      planList: [],
      ggList: [],
      // 查询参数
      queryParams: {
        current: 1,
        size: 15,
        name: "",
        no: ""
      },
      ggactiveName: '',
      loading1: false,
      loading2: false,
    }
  },
  computed: {

  },
  created() {
    this.info();
  },
  methods: {
    newgg(item) {
      window.open('/Article?type=10&id=' + item.id);
    },
    newDetail(item) {
      window.open('/Article?type=' + this.activeone + '&projectId=' + item.projectId + '&project=1');
    },
    handleClick({index}) {
      this.getplanList(index)
    },
    getplanList(index) {
      this.loading1 = true
      let queryParams1 = {
        current: 1,
        size: 8,
        type: index,
      }
      getplanindex(queryParams1).then(res => {
        this.planList = res.data.records
        this.loading1 = false
      })
    },
    info() {
      this.getplanList(0)
      getDict({dictTypeId: '1789911809167310849'}).then(res => {
        this.loading2 = true
        let ggList = res.data;
        getnoticelistindex().then(res => {
          this.noticeList = res.data
          this.loading2 = false
        })
        this.ggList = ggList
        this.ggactiveName = ggList[0].label
        this.$forceUpdate()
      })

    }
  }
}
</script>
<style lang="less" scoped>
.Morebox {
  width: 100%;
  //background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)), url("/public/images/zb/beijing.png");
  background: rgba(244, 244, 244, 0.74);
  background-size: cover;
  padding-bottom: 10px;

  .body {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;

    .list_left {
      width: 780px;
      height: 480px;
      background: #FFFFFF;
      box-shadow: 0 4px 15px 0 #EFEFEF;
      border-radius: 8px 8px 8px 8px;

      .list_left1 {
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .shu {
          width: 4px;
          height: 16px;
          background: #0059BC;
          margin-left: 28px;
          margin-right: 10px;
        }

        .zi {
          font-size: 22px;
          color: #0059BC;
        }

        .gd {
          color: #8D8D8D;
          font-size: 14px;
          cursor: pointer;
          padding-right: 30px;
        }
      }

      .list_left2 {
        width: 794px;
        height: 2px;
        background: #EFEFEF;
        margin: auto;
      }

      .list_left3 {
        width: 794px;
        display: flex;
        justify-content: space-between;

        .list_left31 {
          display: flex;
          color: #333333;
          font-size: 18px;

          .tab1 {
            width: 114px;
            height: 35px;
            background: #EFEFEF;
            border-radius: 111px 111px 111px 111px;
            line-height: 35px;
            text-align: center;
            margin-right: 25px;
            cursor: pointer;
          }

          .tab2 {
            width: 114px;
            height: 35px;
            background: #EFEFEF;
            border-radius: 111px 111px 111px 111px;
            line-height: 35px;
            text-align: center;
            cursor: pointer;
          }

          .tab3 {
            color: #1971FA;
            background-color: #E7F1FF;
          }
        }

        .list_left32 {
          color: #8D8D8D;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .list_left4 {
        width: 725px;
        margin: auto;
        font-size: 15px;

        .tonzhi {
          display: flex;
          white-space: nowrap;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #EFEFEF;
          color: #4b4b4b;
          //margin: 15px 0;
          padding: 10px 0 10px 0;
          cursor: pointer;
          //height: 53px;

          &:hover {
            color: #3e85f5;
            background-color: #f8fbff;
          }

          .left {
            display: flex;
            align-items: center;
            width: 620px;
            overflow: hidden;
            text-overflow: ellipsis;

            img {
              width: 20px;
              height: 20px;
            }

            .text2 {
              overflow: hidden;
              text-overflow: ellipsis;
              padding-left: 10px;

            }
          }

          .riqi {
          }
        }
      }
    }

    .list_right {
      width: 365px;
      height: 480px;
      padding: 0 20px;
      background: #FFFFFF;
      box-shadow: 0 4px 15px 0 #EFEFEF;
      border-radius: 8px 8px 8px 8px;

      /deep/ .el-tabs__item {
        padding: 0 15px
      }

      /deep/ .el-tabs__item.is-top:nth-child(2) {
        padding-left: 15px !important;
      }

      /deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
        padding-right: 15px;
      }

      .list_right1 {
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;

        .shu {
          width: 4px;
          height: 16px;
          background: #0059BC;
          margin-right: 10px;
        }

        .zi {
          font-size: 22px;
          color: #0059BC;
        }

        .gd {
          color: #8D8D8D;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .list_right2 {
        height: 2px;
        background: #EFEFEF;
        margin: auto;
      }

      .list_right3 {
        margin: auto;
        margin-top: 4px;

        .gg {
          width: 320px;
          margin: 0 0 10px 20px;
        }

        .text {
          display: flex;
          white-space: nowrap;
          font-size: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #4b4b4b;
          border-bottom: 1px solid #EFEFEF;
          height: 40px;
          align-items: center;
          cursor: pointer;

          .text2 {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

/deep/ .el-tabs__item.is-active {
  color: #fff !important;
  background: #3692f7;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

/deep/ .el-tabs__item.is-top:nth-child(2) {
  padding-left: 20px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 20px;
}

/deep/ .el-tabs__item:hover {
  color: #3692f7;
}

/deep/ .el-tabs__active-bar {
  background-color: #3692f7;
}
</style>
