<template>
  <div class="UsBody">
    <div class="body">
      <div class="title">奥德集团</div>
      <div class="top">
        <div class="text1">
          <img src="images/zb/address.png">
          <div>
            <div class="text3">山东省临沂市河东区北京东路与智圣路交汇</div>
            <div>
              奥德商务中心806招投标办公室 邮政编码：276000
            </div>
          </div>
        </div>
        <div class="text2">
          <img src="images/zb/email.png">
          <div>
            <div class="text3">ztborder@odc.hk</div>
          </div>
        </div>
      </div>

      <div class="bottom">
        <div class="text1">
          <img src="images/zb/tel.png">
          <div>
            <div class="text3">招投标办公室座机号：0539-8821995</div>
            <div class="text3">招投标业务联系人：王经理 18705490201</div>
          </div>
        </div>
        <div class="text2">
          <img src="images/zb/people.png">
          <div>
            <div class="text3">监督投诉电话：0539-8821995</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Us"
}
</script>

<style scoped lang="css">
.UsBody {
  color: #fff;
  width: 100%;
  background-image: url("/public/images/zb/beauty.png");
  height: 650px;
  background-repeat: no-repeat; /* 不重复背景图片 */
  background-position: center center; /* 水平垂直居中 */
  background-size: cover; /* 背景图片覆盖整个元素区域 */
  position: relative;

  .body {
    position: absolute;
    width: 1300px;
    height: 335px;
    background-image: url("/public/images/zb/cc_top.png");
    opacity: 0.9;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    left: 50%;
    margin-left: -650px;
    top: 315px;
    border-radius: 50px 50px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      width: 1000px;
      font-size: 30px;
      padding: 40px 0;
    }

    .top {
      width: 1000px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    .bottom {
      width: 1000px;
      display: flex;
      justify-content: space-between;
    }

    .text1 {
      display: flex;
      font-size: 14px;
      line-height: 20px;
    }

    .text2 {
      display: flex;
      width: 270px;
      font-size: 14px;
    }

    img {
      width: 35px;
      height: 35px;
      margin-right: 20px;
    }

    .text3 {
      line-height: 30px;
    }
  }
}
</style>