<template>
  <div class="body">
    <div class="top">
      找回密码
    </div>
    <div class="container">
      <el-row>
        <div class="form">
          <el-form ref="form" :model="form" label-width="130px" v-loading="loading" :rules="rules">

            <el-form-item label="手机号" ref="phone" prop="phone">
              <el-input v-model="form.phone" placeholder="手机号">
                <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
              </el-input>
            </el-form-item>

            <el-form-item label="手机验证码" ref="sjyzmCode" prop="sjyzmCode">
              <div class="sjyzm">
                <el-input class="input" v-model="form.sjyzmCode" placeholder="手机验证码">
                  <i slot="prefix" class="el-input__icon el-icon-chat-line-square"></i>
                </el-input>
                <el-button @click="startCountdown" class="button" type="primary" :disabled="isDisabled">
                  获取验证码<span v-if="isDisabled">({{ countdown }})</span>
                </el-button>
              </div>
            </el-form-item>
            <el-form-item label="密码" ref="password" prop="password">
              <el-input v-model="form.password" type="password" show-password placeholder="密码">
                <i slot="prefix" class="el-input__icon el-icon-document-copy"></i>
              </el-input>
            </el-form-item>

            <el-form-item label="确认密码" ref="qrpassword" prop="qrpassword">
              <el-input v-model="form.qrpassword" type="password" show-password placeholder="确认密码">
                <i slot="prefix" class="el-input__icon el-icon-document-copy"></i>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="ZCButton">
            <el-button class="Button" type="primary" @click="addSupplier()">提交</el-button>
          </div>
        </div>
      </el-row>
    </div>


    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleRegister"
        width="30%"
    >
      <span>您已修改成功，请前往首页登录</span>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="qrDialogRegister">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {getAuthCodeLogin,updatePassword} from "@/api/home.js"

export default {
  name: 'Register',
  data() {
    return {
      dialogVisibleRegister: false,
      loading: false,
      captchaImg: '',
      checkbox: false,
      form: {
        phone: '',
        businessLicense: '',
        sjyzmCode: '',
        password: '',
        qrpassword: ''
      },
      rules: {
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {
            pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
            message: '请输入正确的密码(密码是数字、大小写字母、特殊字符的组合)',
            trigger: 'blur'
          }
        ],
        qrpassword: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {
            pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/,
            message: '请输入正确的密码(密码是数字、大小写字母、特殊字符的组合)',
            trigger: 'blur'
          }

        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'}
        ],

        sjyzmCode: [
          {required: true, message: '请输入手机验证码', trigger: 'blur'}
        ],

      },
      required: true,
      countdown: 60,
      isDisabled: false,
      timer: null,

    }
  },
  watch: {},
  created() {

  },
  mounted() {
  },
  methods: {

    startCountdown() {
      if (this.form.phone == "" || !(/^1\d{10}$/.test(this.form.phone))) {
        this.$message.error("请填写手机号")
        return;
      }

      if (this.isDisabled) return;
      this.isDisabled = true;
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
          this.isDisabled = false;
          this.countdown = 60;
        }
      }, 1000);
      getAuthCodeLogin({phone: this.form.phone}).then((res) => {
        this.$message.success(res.data)
      }).catch((res) => {
        this.$message.error(res.response.data)
      })
    },

    resetFrom() {
      this.form = {
        phone: '',
        businessLicense: '',
        sjyzmCode: '',
        password: '',
        qrpassword: ''
      }
    },
    qrDialogRegister() {
      this.dialogVisibleRegister = false

      this.$router.push({name: 'home'})

    },
    addSupplier() {

      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.password && (this.form.password !== this.form.qrpassword)) {
            this.$notify({
              title: '警告',
              message: '两次密码不一致',
              type: 'warning'
            });
            return;
          }
          this.loading = true
          updatePassword(this.form).then((res) => {
            this.resetFrom()
            this.dialogVisibleRegister = true
            this.loading = false
          }).catch((err) => {
            this.$alert(err.response.data, '提示', {
              confirmButtonText: '确定',
              type: 'error',
            })
            this.loading = false
          })
        } else {
          this.$notify({
            title: '警告',
            message: '请填写正确内容',
            type: 'warning'
          });
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  position: relative;
  padding-top: 100px;

  .top {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #1482F0;
    height: 150px;
    text-align: center;
    line-height: 100px;
    font-size: 35px;
    color: #fff;
    z-index: -1;
  }

  .container {
    width: 1200px;
    background-color: #fff;
    margin: 0 auto 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding-bottom: 20px;

    .bz {
      margin: auto;
      width: 1150px;
      padding-top: 30px;
    }

    .form {
      margin-left: 30px;
      width: 900px;
      padding-top: 30px;

      .yzm {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .input {
          width: 85%;
        }

        .image {
          width: 130px;
          height: 40px;
          border: 1px solid #e1e1e1;
          border-radius: 5px;
        }
      }

      .sjyzm {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .input {
          width: 73%;
        }

        .button {
          width: 250px;
          height: 40px;
          border: 1px solid #e1e1e1;
          border-radius: 5px;
        }
      }

      .ZCButton {
        display: flex;
        justify-content: center;

        .Button {
          width: 400px;
          height: 45px;
        }
      }

      .zhuyi {
        padding-top: 10px;
        color: #FF0000;
      }

      .xieyi {
        display: flex;
      }

      .businessLicense1 {
        /deep/ .el-upload--picture-card {
          display: none;
        }
      }

      .card1 {
        /deep/ .el-upload--picture-card {
          display: none;
        }
      }

      .card2 {
        /deep/ .el-upload--picture-card {
          display: none;
        }
      }

      .businessLicense {
        position: relative;

        /deep/ .el-upload--picture-card {
          width: 360px;
          height: 180px;
          border: 1px none #d9d9d9;
        }

        /deep/ .el-upload-list__item {
          width: 360px;
          height: 180px;
        }

        .wenzi1 {
          position: absolute;
          top: 85px;
          left: 50%;
          width: 200px;
          margin-left: -100px;
          text-align: center;
        }

        .wenzi {
          position: absolute;
          top: 40px;
          left: 50%;
          width: 200px;
          margin-left: -100px;
          text-align: center;
        }
      }

    }
  }
}

.card2img {
  width: 200px;
  height: 138px;
  margin: auto;
  margin-top: 10px;
  background-image: url('/public/images/card2.png');
  background-size: cover;
}

.card1img {
  width: 200px;
  height: 138px;
  margin: auto;
  margin-top: 10px;
  background-image: url('/public/images/card1.png');
  background-size: cover;
}

</style>
