<template>
  <div class="body">
    <div class="topImg"></div>
    <div class="ltop">
      <div class="mbx">
        <div class="info">
          所在位置：
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item,index) in mbx" :key="index">{{ item.title }}
            </el-breadcrumb-item>
            <el-breadcrumb-item>{{ tabList[tabIndex].title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <div class="bt">
      <div class="left ListLeftTab">
        <div class="info" :class="index ===tabIndex?'info1':''" v-for="(item,index) in tabList"
             @click="clickTab(index)">
          <div class="text">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="right">
        <List ref="titleList" :tabIndex="10" :url="url" :type="type"></List>
      </div>
    </div>
  </div>
</template>

<script>
import List from "@/views/List.vue";
import {getDict} from "@/api/home";

export default {
  name: "Gonggao",
  components: {List},
  data() {
    return {
      routerList: [],
      tabIndex: 0,
      tabList: [{
        title: '全部公告'
      }],
      mbx: [],
      url: '',
      type: '',
      query: {
        type: ''
      }
    }
  },
  created() {
    this.getRoute()
  },
  mounted() {
  },
  watch: {
    "query.type": {
      handler(newValue, oldValue) {
        if (newValue == "gonggao") {
          this.url = "/api/noticelist"
          this.getDictListGonggao()
        } else if (newValue == "project1") {
          this.url = "/api/getPlan1List"
          this.getDictProject1()
        }else if (newValue == "project2") {
          this.url = "/api/project2index"
          this.getDictProject2()

        }
      }
    }
  },
  methods: {
    getDictProject2(){
      let tabList = []
      getDict({dictTypeId: '1786809368735649793'}).then(res => {
        let ggList = res.data;
        ggList.forEach(item => {
          item.title = item.label
        })
        tabList.push(...ggList)
        this.tabList = tabList
        this.$nextTick(()=>{
          this.clickTab(0)
        })
      })

    },
    getDictProject1() {
      this.tabList = [
        {
          title: '招标计划',
          value: 0
        }, {
          title: '招标公告',
          value: 1
        }, {
          title: '投标单位公示',
          value: 2
        }, {
          title: '踏勘与答疑',
          value: 3
        }, {
          title: '开标评标定标',
          value: 4
        }, {
          title: '中标通知书',
          value: 5
        }, {
          title: '招标后评价',
          value: 6
        }
      ]
      this.$nextTick(()=>{
        this.clickTab(0)
      })
    },
    getDictListGonggao() {
      let tabList = [
        {
          title: '全部公告',
        }
      ]
      getDict({dictTypeId: '1789911809167310849'}).then(res => {
        let ggList = res.data;
        ggList.forEach(item => {
          item.title = item.label
        })
        tabList.push(...ggList)
        this.tabList = tabList
        this.$nextTick(()=>{
          this.clickTab(0)
        })
      })
    },
    clickTab(index) {
      this.tabIndex = index
      let data
      if (this.query.type==="gonggao"|| this.query.type==="project1"){
        data = {
          type:this.tabList[index].value
        }
      }else if(this.query.type==="project2"){
        data = {
          area:this.tabList[index].value
        }
      }
      this.$refs.titleList.init(data);
    },
    getRoute() {
      let router = this.$router
      let route = this.$route
      let mbx = []
      //
      // route.matched.forEach((item) => {
      //   let data = {
      //     title: item.meta.title,
      //     name: item.name
      //   };
      //   mbx.push(data)
      // })
      this.query = route.query
      if (this.query.type=="gonggao"){
        mbx = [{"title":"公告信息","name":"Gonggao"}]
      }else if (this.query.type=="project1"){
        mbx = [{"title":"一类项目","name":"Gonggao"}]
      }else if (this.query.type=="project2"){
        mbx = [{"title":"二类项目","name":"Gonggao"}]
      }
      this.mbx = mbx

      this.routerList = router.options.routes.filter((item) => {
        return item.name === route.matched[0].name
      })[0].children
    }
  }
}
</script>

<style scoped lang="less">
.body {
  width: 100%;
  background-color: #FAFBFD;


  .bt {
    width: 1200px;
    margin: auto;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;


    .right {
      width: 929px;
    }
  }
}
/deep/ .has-gutter{
  color:#000 !important;
}


</style>