<template>
  <div class="body">
    <div class="row">
      <div class="left">
        <div class="text">
          <div class="text1">
            <div class="text11">奥德</div>
            <div class="text12">阳光招采平台</div>
          </div>
          <div class="text2">
            <div class="">诚信生态</div>
            <div class="">共赢进化</div>
            <div class="">数字监督</div>
            <div class="">便捷服务</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div v-if="!wjmm" class="login">
          <el-tabs v-model="activeName">
            <el-tab-pane label="短信登录" name="note">
              <el-form ref="loginFormPhone" :model="loginFormPhone" label-width="80px">
                <el-form-item label-width="0">
                  <el-input v-model="loginFormPhone.phone" placeholder="请输入手机号">
                    <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="0">
                  <div class="yzm">
                    <el-input v-model="loginFormPhone.code" class="input" placeholder="请输入验证码">
                      <i slot="prefix" class="el-input__icon el-icon-chat-line-square"></i>
                    </el-input>
                    <el-button type="primary">获取验证码</el-button>
                  </div>
                </el-form-item>
                <el-button class="button" type="primary" @click="login('phone')">登录</el-button>
                <div class="zc2">
                  没有账号？
                  <el-link style="color: #51B0FF" @click="$router.push({name: 'Register'})">注册</el-link>
                </div>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="密码登录" name="password">
              <el-form ref="loginForm" :model="loginFormPassWord" label-width="80px">
                <el-form-item label-width="0">
                  <el-input v-model="loginFormPassWord.loginName" placeholder="请输入用户名">
                    <i slot="prefix" class="el-input__icon el-icon-user"></i>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="0">
                  <el-input type="password" show-password v-model="loginFormPassWord.password" placeholder="请输入密码"
                            class="input">
                    <i slot="prefix" class="el-input__icon el-icon-chat-line-square"></i>
                  </el-input>
                </el-form-item>

                <el-button class="button" type="primary" @click="login('password')">登录</el-button>
                <div class="zc">
                  <div>
                    <el-link style="color: #51B0FF" @click="wjmm = true">忘记密码？</el-link>
                  </div>
                  <div class="zc1">
                    没有账号？
                    <el-link style="color: #51B0FF" @click="$router.push({name: 'Register'})">注册</el-link>
                  </div>
                </div>
              </el-form>
            </el-tab-pane>
          </el-tabs>

        </div>
        <div v-else class="login">
          <el-tabs v-model="activeName1">
            <el-tab-pane label="忘记密码" name="note1">
              <el-form ref="loginForm" :model="passWordForm" label-width="80px">
                <el-form-item label-width="0">
                  <el-input type="password" show-password v-model="passWordForm.password" placeholder="请输入密码">
                    <i slot="prefix" class="el-input__icon el-icon-chat-line-square"></i>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="0">
                  <el-input type="password1" show-password v-model="passWordForm.password1" placeholder="请确认密码">
                    <i slot="prefix" class="el-input__icon el-icon-chat-line-square"></i>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="0">
                  <el-input type="phone" show-password v-model="passWordForm.phone" placeholder="请输入手机号">
                    <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
                  </el-input>
                </el-form-item>
                <el-form-item label-width="0">
                  <div class="yzm">
                    <el-input v-model="passWordForm.code" class="input" placeholder="请输入验证码">
                      <i slot="prefix" class="el-input__icon el-icon-chat-line-square"></i>
                    </el-input>
                    <el-button type="primary">获取验证码</el-button>
                  </div>
                </el-form-item>
                <el-button class="button" type="primary">登录</el-button>
                <div class="zc2">
                  <el-link style="color: #51B0FF" @click="wjmm = false">返回登录</el-link>
                </div>

              </el-form>
            </el-tab-pane>
          </el-tabs>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {loginPassword} from "@/api/system/supplier";
import vueCookie from "vue-cookie";

export default {
  name: 'Login',
  data() {
    return {
      wjmm: false,
      activeName: 'note',
      activeName1: 'note1',
      loginFormPassWord: {
        loginName: '',
        password: ""
      },
      loginFormPhone: {
        phone: "",
        code: ""
      },
      passWordForm: {
        password: '',
        password1: '',
        code: '',
        phone: ''
      }
    }
  },
  watch: {},
  mounted() {

  },
  methods: {
    login(type) {
      if (type === 'phone') {
        console.log(1)
      } else if (type === 'password') {
        loginPassword(this.loginFormPassWord).then((res) => {
          vueCookie.set('token', res.data.token)
          this.$notify({
            title: '登录成功',
            message: `欢迎回来！`,
            dangerouslyUseHTMLString: true,
            duration: 10000,
            type: 'success'
          })
          this.$router.push({name: 'home'})
        }).catch((err) => {
          this.$message.error(err.response.data);
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  background-color: #FAFBFD;

  .row {
    width: 1600px;
    height: 600px;
    margin: auto;
    display: flex;

    .left {
      width: 1000px;
      height: 600px;

      .text {
        width: 100%;
        height: 300px;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 100px;

        .text1 {
          display: flex;
          font-size: 45px;
          font-weight: bold;
          margin-bottom: 20px;
          width: 360px;

          .text11 {
            color: #80BBF8;
          }

          .text12 {
            color: #6BB9FA;
          }
        }

        .text2 {
          width: 360px;
          display: flex;
          color: #73AFF5;
          font-weight: bold;
          font-size: 18px;

          div {
            margin-right: 10px;
          }
        }
      }
    }

    .right {
      width: 600px;
      height: 600px;
      background-color: rgba(169, 184, 238, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;

      .login {
        background-color: #fff;
        width: 350px;
        padding: 30px;
        border-radius: 5px;

        .yzm {
          width: 100%;
          display: flex;
          justify-content: space-between;

          .input {
            width: 220px;
          }
        }

        .button {
          width: 100%;
        }

        .zc {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .zc1 {
            display: flex;
            justify-content: end;
            align-items: center;
            font-size: 14px;
          }
        }

        .zc2 {
          margin-top: 10px;
          display: flex;
          justify-content: end;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
