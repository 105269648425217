<template>
  <div class="body">
    <div class="top">
      供应商注册
    </div>
    <div class="container">
      <!--      <div class="bz">-->
      <!--        <el-steps  :active="processActive">-->
      <!--          <el-step  title="提交中" ></el-step>-->
      <!--          <el-step title="审核中" ></el-step>-->
      <!--          <el-step :status="processStatus" :title="processTitle" ></el-step>-->
      <!--        </el-steps>-->
      <!--      </div>-->
      <el-row>
      <div class="form">
        <el-form ref="form" :model="form" label-width="130px" v-loading="loading" :rules="rules" >
          <el-form-item label="单位名称" ref="name" prop="name">
            <el-input v-model="form.name" placeholder="单位名称">
              <i slot="prefix" class="el-input__icon el-icon-notebook-2"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="社会信用代码" ref="code" prop="code">
            <el-input v-model="form.code" placeholder="社会信用代码">
              <i slot="prefix" class="el-input__icon el-icon-reading"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="法人名称" ref="legalPerson" prop="legalPerson">
            <el-input v-model="form.legalPerson" placeholder="法人名称">
              <i slot="prefix" class="el-input__icon el-icon-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="邮箱" ref="email" prop="email">
            <el-input v-model="form.email" placeholder="邮箱">
              <i slot="prefix" class="el-input__icon el-icon-folder"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="联系人" ref="contacts" prop="contacts">
            <el-input v-model="form.contacts" placeholder="联系人">
              <i slot="prefix" class="el-input__icon el-icon-user"></i>
            </el-input>
          </el-form-item>

          <el-form-item label="手机号" ref="phone" prop="phone">
            <el-input v-model="form.phone" placeholder="手机号">
              <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
            </el-input>
          </el-form-item>
          <el-form-item label="验证码" ref="yzmCode" prop="yzmCode">
            <div class="yzm">
              <el-input v-model="form.yzmCode" placeholder="验证码" class="input">
                <i slot="prefix" class="el-input__icon el-icon-chat-dot-square"></i>
              </el-input>
              <el-image class="image" @click="getCaptcha" :src="captchaImg"></el-image>
            </div>
          </el-form-item>
          <el-form-item label="手机验证码" ref="sjyzmCode" prop="sjyzmCode">
            <div class="sjyzm">
              <el-input class="input" v-model="form.sjyzmCode" placeholder="手机验证码">
                <i slot="prefix" class="el-input__icon el-icon-chat-line-square"></i>
              </el-input>
              <el-button  @click="startCountdown" class="button" type="primary" :disabled="isDisabled">
                获取验证码<span v-if="isDisabled">({{ countdown }})</span>
              </el-button>
            </div>
          </el-form-item>
          <div v-if="!form.id">
            <el-form-item label="密码" ref="password" prop="password">
              <el-input v-model="form.password" type="password" show-password placeholder="密码">
                <i slot="prefix" class="el-input__icon el-icon-document-copy"></i>
              </el-input>
            </el-form-item>

            <el-form-item label="确认密码" ref="qrpassword" prop="qrpassword">
              <el-input v-model="form.qrpassword" type="password" show-password placeholder="确认密码">
                <i slot="prefix" class="el-input__icon el-icon-document-copy"></i>
              </el-input>
            </el-form-item>
          </div>
          <el-form-item label="营业执照照片" ref="businessLicense" prop="businessLicense">
            <el-upload
                class="businessLicense"
                :class="isUpdate.businessLicense?'businessLicense1':''"
                :action="uploadUrlBusinessLicense"
                list-type="picture-card"
                accept="image/jpg,image/jpeg,image/png"
                :before-upload="handleBeforeUpload"
                :on-preview="(file)=>uploadPreview(file,1)"
                :limit="1"
                drag
                :on-exceed="(files, fileList)=>exceedChange(files, fileList,1)"
                :on-remove="()=>removeChange(1)"
                :on-success="(response, file, fileList)=>successChange(response, file, fileList,1)"
            >
              <img :src="form.card1" width="360px" height="180px" v-if="form.businessLicense" :alt="form.card1">
              <i v-if="!form.businessLicense" class="el-icon-upload"></i>
              <div v-if="!form.businessLicense" class="wenzi">将文件拖到此处，或<span
                  style="color: #409EFF;">点击上传</span></div>
            </el-upload>

            <el-dialog :visible.sync="dialogVisible.businessLicense">
              <img width="100%" :src="dialogImageUrl.businessLicense" alt="">
            </el-dialog>
          </el-form-item>

          <el-form-item label="法人身份证照片" ref="card1" prop="card1">
            <el-upload
                class="businessLicense"
                :class="isUpdate.card1?'card1':''"
                :action="uploadUrlCard"
                list-type="picture-card"
                :on-preview="(file)=>uploadPreview(file,2)"
                :limit="1"
                accept="image/jpg,image/jpeg,image/png"
                :before-upload="handleBeforeUpload"
                drag
                :on-exceed="(files, fileList)=>exceedChange(files, fileList,2)"
                :on-remove="()=>removeChange(2)"
                :on-success="(response, file, fileList)=>successChange(response, file, fileList,2)"
            >
              <img :src="form.card1" width="360px" height="180px" v-if="form.card1" :alt="form.card1">
<!--              <i v-if="!form.card1" class="el-icon-upload"></i>-->
              <div v-if="!form.card1" class="card1img"></div>

              <div v-if="!form.card1" class="wenzi1">法人身份证正面<span style="color: #409EFF;">点击上传</span></div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible.card1">
              <img width="100%" :src="dialogImageUrl.card1" alt="">
            </el-dialog>
          </el-form-item>
          <el-form-item label="" ref="card2" prop="card2">
            <el-upload
                class="businessLicense"
                :class="isUpdate.card2?'card2':''"
                :action="uploadUrlCard"
                list-type="picture-card"
                :on-preview="(file)=>uploadPreview(file,3)"
                :limit="1"
                accept="image/jpg,image/jpeg,image/png"
                :before-upload="handleBeforeUpload"
                drag
                :on-exceed="(files, fileList)=>exceedChange(files, fileList,3)"
                :on-remove="()=>removeChange(3)"
                :on-success="(response, file, fileList)=>successChange(response, file, fileList,3)"
            >
              <img :src="form.card1" width="360px" height="180px" v-if="form.card2" :alt="form.card1">
<!--              <i v-if="!form.card2" class="el-icon-upload"></i>-->
              <div v-if="!form.card2" class="card2img"></div>
              <div v-if="!form.card2" class="wenzi1">法人身份证反面<span style="color: #409EFF;">点击上传</span></div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible.card2">
              <img width="100%" :src="dialogImageUrl.card2" alt="">
            </el-dialog>
          </el-form-item>
          <el-form-item>
            <div class="xieyi">
              <el-checkbox v-model="checkbox" style="margin-right: 10px"></el-checkbox>
              我已阅读并同意
              <el-link type="danger" :underline="false">《用户协议》</el-link>
              和
              <el-link type="danger" :underline="false">《隐私政策》</el-link>
            </div>
          </el-form-item>

        </el-form>

        <div class="ZCButton">
          <el-button class="Button" type="primary" @click="addSupplier()">注册</el-button>
        </div>
      </div>
      </el-row>
    </div>



    <el-dialog
        title="提示"
        :visible.sync="dialogVisibleRegister"
        width="30%"
        >
      <span>您已注册成功，请前往首页登录</span>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="qrDialogRegister">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {getAuthCode, getCode} from "@/api/home.js"
import {registerSupplier, getSupplierInfo, updateSupplier} from "@/api/system/supplier";
import vueCookie from "vue-cookie";

export default {
  name: 'Register',
  data() {
    return {
      dialogVisibleRegister:false,
      loading:false,
      uploadUrlBusinessLicense: process.env.VUE_APP_BASE_API + "/api/webupload/upload?uploadPath=/project/supplier/businessLicense",
      uploadUrlCard: process.env.VUE_APP_BASE_API + "/api/webupload/upload?uploadPath=/project/supplier/Card",
      captchaImg: '',
      checkbox: false,
      form: {
        id: '',
        name: '',
        code: '',
        legalPerson: '',
        email: '',
        contacts: '',
        phone: '',
        businessLicense: '',
        card1: '',
        card2: '',
        state: '',
        remark: '',
        yzmCode: '',
        sjyzmCode: '',
        password: '',
        qrpassword: ''
      },
      dialogVisible: {
        businessLicense: false,
        card1: false,
        card2: false
      },
      dialogImageUrl: {
        businessLicense: '',
        card1: '',
        card2: ''
      },
      isUpdate: {
        businessLicense: false,
        card1: false,
        card2: false
      },
      rules: {
        name: [{required: true, message: '请输入单位名称', trigger: 'blur'}],
        code: [
          {required: true, message: '请输入信用代码', trigger: 'blur'}
        ],
        legalPerson: [
          {required: true, message: '请输入法人名称', trigger: 'blur'}
        ],
        email: [
          {required: true, message: '请输入邮箱', trigger: 'blur'},
          {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change']}
        ],
        contacts: [
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/, message: '请输入正确的密码(密码是数字、大小写字母、特殊字符的组合)', trigger: 'blur'}
        ],
        qrpassword: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/, message: '请输入正确的密码(密码是数字、大小写字母、特殊字符的组合)', trigger: 'blur'}

        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur'}
        ],
        yzmCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'}
        ],
        sjyzmCode: [
          {required: true, message: '请输入手机验证码', trigger: 'blur'}
        ],
        businessLicense: [
          {required: true, message: '请输入营业执照照片', trigger: 'blur'}
        ],
        card1: [
          {required: true, message: '请输入法人身份证照片', trigger: 'blur'}
        ],
        card2: [
          {required: true, message: '请输入法人身份证照片', trigger: 'blur'}
        ]
      },
      required: true,
      countdown: 60,
      isDisabled: false,
      timer: null,
      // processStatus:"process",
      // processActive:1,
      // processTitle:"结果"
    }
  },
  watch: {},
  created() {
    let token = vueCookie.get("token")
    if (token) {
      // this.getSupplierInfo()
    }
    this.getCaptcha()
  },
  mounted() {

  },
  methods: {
    handleBeforeUpload(file) {
      var img = file.name.substring(file.name.lastIndexOf('.') + 1)
      const suffix = img === 'jpg'
      const suffix2 = img === 'png'
      const suffix3 = img === 'jpeg'
      if (!suffix && !suffix2) {
        this.$message.error("只允许上传图片");
        this.$alert("只允许上传图片", '提示',{
          confirmButtonText: '确定',
          type: 'error',
        })
        return false
      }
      return suffix || suffix2 || suffix3
    },
    startCountdown(){
      if (this.form.phone==""||!(/^1\d{10}$/.test(this.form.phone))){
        this.$message.error("请填写手机号")
        return;
      }

      if (this.isDisabled) return;
      this.isDisabled = true;
      this.timer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.timer);
          this.isDisabled = false;
          this.countdown = 60;
        }
      }, 1000);
      getAuthCode({phone:this.form.phone}).then((res)=>{
        this.$message.success(res.data)
      }).catch((res)=>{
        this.$message.error(res.response.data)
      })
    },
    getSupplierInfo() {
      getSupplierInfo().then((res) => {
        if (res.data) {
          Object.assign(this.form, res.data);
          this.dialogImageUrl.businessLicense = res.data.businessLicense
          this.dialogImageUrl.card1 = res.data.card1
          this.dialogImageUrl.card2 = res.data.card2

          this.required = false
          this.rules.password = ""
          this.rules.qrpassword = ""
        }

        // if (res.data.state==='0'){
        //   this.processActive=2
        // }if (res.data.state==='1'){
        //   this.processActive=3
        //   this.processStatus="success"
        //   this.processTitle="成功";
        // }if (res.data.state==='2'){
        //   this.processActive=3
        //   this.processStatus="error"
        //   this.processTitle="失败";
        // }

      }).catch((err) => {
      })
    },
    uploadPreview(file, type) {
      if (type === 1) {
        this.dialogImageUrl.businessLicense = file.url;
        this.dialogVisible.businessLicense = true;
      } else if (type === 2) {
        this.dialogImageUrl.card1 = file.url;
        this.dialogVisible.card1 = true;
      } else if (type === 3) {
        this.dialogImageUrl.card2 = file.url;
        this.dialogVisible.card2 = true;
      }
    },
    removeChange(type) {
      if (type === 1) {
        this.form.businessLicense = ""
        this.isUpdate.businessLicense = false
      } else if (type === 2) {
        this.form.card1 = ""
        this.isUpdate.card1 = false
      } else if (type === 3) {
        this.form.card2 = ""
        this.isUpdate.card2 = false
      }
    },
    successChange(response, file, fileList, type) {
      if (type === 1) {
        this.form.businessLicense = response.url
        this.isUpdate.businessLicense = true;
      } else if (type === 2) {
        this.isUpdate.card1 = true;
        this.form.card1 = response.url
      } else if (type === 3) {
        this.isUpdate.card2 = true;
        this.form.card2 = response.url
      }
    },
    exceedChange(files, fileList, type) {
      if (type === 1) {
        this.$message.warning(`只能上传一个文件`);
      } else if (type === 2) {
        this.$message.warning(`只能上传一个文件`);
      } else if (type === 3) {
        this.$message.warning(`只能上传一个文件`);
      }
    },
    getCaptcha() {
      getCode().then(({data}) => {
        this.captchaImg = 'data:image/gif;base64,' + data.codeImg
        this.form.uuid = data.uuid
      })
    },
    resetFrom() {
      this.form = {
        id: '',
        name: '',
        code: '',
        legalPerson: '',
        email: '',
        contacts: '',
        phone: '',
        businessLicense: '',
        card1: '',
        card2: '',
        state: '',
        remark: '',
        yzmCode: '',
        sjyzmCode: ''
      }
    },
    qrDialogRegister(){
      this.dialogVisibleRegister = false

      this.$router.push({name: 'home'})

    },
    addSupplier() {

      if (!this.checkbox) {
        this.$notify({
          title: '警告',
          message: '请同意隐私协议',
          type: 'warning'
        });
        return;
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {

          if (this.form.password && (this.form.password !== this.form.qrpassword)) {
            this.$notify({
              title: '警告',
              message: '两次密码不一致',
              type: 'warning'
            });
            return;
          }
          this.loading = true
          if (this.form.id) {
            // updateSupplier(this.form).then((res) => {
            //   this.$message.success(`保存成功`);
            //   this.resetFrom()
            //   this.getSupplierInfo()
            // }).catch((err) => {
            //   this.$message.error(err.response.data);
            //   if (err.response.data === "您输入的验证码不正确，请重新输入！") {
            //     this.getCaptcha()
            //   }
            // })
          } else {
            registerSupplier(this.form).then((res) => {
              this.resetFrom()
              this.dialogVisibleRegister = true
              this.loading = false
            }).catch((err) => {
              this.$alert(err.response.data, '提示',{
                confirmButtonText: '确定',
                type: 'error',
              })
              if (err.response.data === "您输入的验证码不正确，请重新输入！") {
                this.getCaptcha()
              }
              this.loading = false
            })
          }
        } else {
          this.$notify({
            title: '警告',
            message: '请填写正确内容',
            type: 'warning'
          });
          return false;
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
.body {
  width: 100%;
  position: relative;
  padding-top: 100px;

  .top {
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #1482F0;
    height: 150px;
    text-align: center;
    line-height: 100px;
    font-size: 35px;
    color: #fff;
    z-index: -1;
  }

  .container {
    width: 1200px;
    background-color: #fff;
    margin: 0 auto 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding-bottom: 20px;

    .bz {
      margin: auto;
      width: 1150px;
      padding-top: 30px;
    }

    .form {
      margin-left: 30px;
      width: 900px;
      padding-top: 30px;

      .yzm {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .input {
          width: 85%;
        }

        .image {
          width: 130px;
          height: 40px;
          border: 1px solid #e1e1e1;
          border-radius: 5px;
        }
      }

      .sjyzm {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .input {
          width: 73%;
        }

        .button {
          width: 250px;
          height: 40px;
          border: 1px solid #e1e1e1;
          border-radius: 5px;
        }
      }

      .ZCButton {
        display: flex;
        justify-content: center;

        .Button {
          width: 400px;
          height: 45px;
        }
      }

      .zhuyi {
        padding-top: 10px;
        color: #FF0000;
      }

      .xieyi {
        display: flex;
      }

      .businessLicense1 {
        /deep/ .el-upload--picture-card {
          display: none;
        }
      }

      .card1 {
        /deep/ .el-upload--picture-card {
          display: none;
        }
      }

      .card2 {
        /deep/ .el-upload--picture-card {
          display: none;
        }
      }

      .businessLicense {
        position: relative;

        /deep/ .el-upload--picture-card {
          width: 360px;
          height: 180px;
          border: 1px none #d9d9d9;
        }

        /deep/ .el-upload-list__item {
          width: 360px;
          height: 180px;
        }

        .wenzi1 {
          position: absolute;
          top: 85px;
          left: 50%;
          width: 200px;
          margin-left: -100px;
          text-align: center;
        }
        .wenzi {
          position: absolute;
          top: 40px;
          left: 50%;
          width: 200px;
          margin-left: -100px;
          text-align: center;
        }
      }

    }
  }
}
.card2img{
  width: 200px;
  height: 138px;
  margin: auto;
  margin-top: 10px;
  background-image: url('/public/images/card2.png');
  background-size: cover;
}
.card1img{
  width: 200px;
  height: 138px;
  margin: auto;
  margin-top: 10px;
  background-image: url('/public/images/card1.png');
  background-size: cover;
}

</style>
